import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import PlanTools from "../Common/PlanTools";

const MedicarePrescriptionPaymentPlan = () => {
  const [content, isLoading] = useContent("73aKpE7ig7OMC5JwpJFFTa");

  if (isLoading) return <p>Loading...</p>;

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/PartDBasics"> Part D basics </Link>
                        </li>
                        <li>Medicare Prescription Payment Plan</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={content.headline}
              />
            </h1>

            <article className="standard full clearfix">
              <div className="content">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={content.copy}
                />
              </div>

            </article>
          </div>
        </section>

        <aside
          className="col-sm-4 col-md-3 side-section order-md-1"
          role="complementary"
        >
          <div className="region region-sidebar-second">
            {/* <ul className="menu menu--main nav navbar-nav">
              <li className="active active-trail first med-frequently-asked-questions">
                <Link
                  to="/part-d-basics/frequently-asked-questions"
                  className="active-trail is-active"
                >
                  Frequently asked questions
                </Link>
              </li>
              <li className="med-eligibility-requirements">
                <Link to="/PartDBasics/EligibilityRequirements">
                  Eligibility requirements
                </Link>
              </li>
              <li className="med-when-to-enroll">
                <Link to="/PartDBasics/WhenToEnroll">When to enroll</Link>
              </li>
              <li className="med-financial-assistance">
                <Link to="/PartDBasics/FinancialAssistance">
                  Financial assistance
                </Link>
              </li>
              <li className="last med-glossary">
                <Link to="/PartDBasics/Glossary">Glossary</Link>
              </li>
            </ul> */}

            <section
              id="block-homecontact-2"
              className="contact-gradient p-5 mt-5 text-left pull-left block block-block-content block-block-contentc84e5a3b-f998-486b-8e22-4dd0249790fd clearfix"
            >
              <PlanTools />
            </section>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default MedicarePrescriptionPaymentPlan;
